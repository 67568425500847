@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

body {
  background-color: #0d0d0d;
  color: white;
  font-family: "Inter", serif;
  height: 100%;
  width: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Sora", sans-serif;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: #0d0d0d;
  position: relative;
}

.navbar-logo {
  margin-right: 42px;
  width: fit-content;
  height: fit-content;
}

.navbar-logo img {
  width: 100%;
  height: 100%;
}

.logo-icon {
  width: 30px;
  height: 30px;
}

.navbar-links {
  list-style: none;
  display: flex;
  gap: 36px;
}

.navbar-links li a {
  text-decoration: none;
  color: #fff;
  opacity: 0.5;
  font-size: 16px;
  font-weight: 300;
  transition: 0.3s ease;
}

.navbar-links li a:hover {
  opacity: 1;
  transition: 0.3s ease;
}

.navbar-toggle {
  display: none;
  font-size: 24px;
  background: none;
  border: none;
  color: #fff;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.navbar-toggle.active {
  transform: rotate(45deg);
}

section {
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  opacity: 0;
  transform: translateY(100px);
  transition: opacity 0.6s ease, transform 1s ease; /* Animación suave */
}

/* Cuando la sección es visible */
.visible {
  opacity: 1;
  transform: translateY(0); /* La sección se mueve a su posición original */
}

h1 {
  font-size: 64px;
}

#content-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 750px;
}

#text-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

#text-container p {
  font-weight: 200;
  color: white;
  opacity: 0.8;
  font-size: 24px;
  line-height: 150%;
}

#home {
  height: 800px;
  padding: 0 20px;
}

#home .container {
  background-image: url("../public/assets/homebkg.svg");
  background-repeat: no-repeat;
  padding: 40px 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 40px;
  height: 100%;
  background-size: cover;
  background-position: center;
  border-radius: 80px;
  width: 100%;
}

#phoneImg {
  max-width: 250px;
}

#download-container {
  display: flex;
  gap: 10px;
}

.title-container {
  display: flex;
  gap: 16px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 62px;
}

.title-container h2 {
  font-size: 48px;
  font-family: "Sora", sans-serif;
  font-weight: 400;
  background: #ffffff;
  background: linear-gradient(to right, #ffffff 0%, #999999 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.title-container span {
  font-size: 16px;
  width: fit-content;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 4px 10px;
  border-radius: 20px;
  border: 2px solid rgba(255, 255, 255, 0.05);
}

.title-container p {
  font-size: 18px;
  color: rgba(255, 255, 255, 0.8);
  max-width: 600px;
  text-align: center;
  line-height: 150%;
  font-weight: 300;
}

#free-card,
#paid-card {
  border-radius: 6px;
  padding: 32px 20px;
  width: 350px;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.disabledBtn{
  background-color: rgba(255, 255, 255, 0.04) !important;
  color: rgba(255, 255, 255, 0.24) !important;
  cursor: context-menu !important;
  margin-top: auto !important;
}

.card-content{
  display: flex;
  gap: 10px;
  flex-direction: column;
}

#free-card {
  background-color: rgba(255, 255, 255, 0.04);
  outline: 6px solid rgba(255, 255, 255, 0.02);
}

#paid-card {
  background-color: #110e19;
  outline: 6px solid rgba(102, 40, 247, 0.1);
  position: relative;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}

#paid-card::after,
#paid-card::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  translate: -50% -50%;
  z-index: -1;
  padding: 6px;
  border-radius: 12px;
  background-image: conic-gradient(
    from var(--angle),
    transparent 70%,
    #6528f7,
    #5020c4,
    #3b1791,
    rgba(255, 255, 255, 0.2)
  );
  animation: 3s spin linear infinite;
  opacity: 0.5;
}

#paid-card::before {
  filter: blur(1rem);
}

@keyframes spin {
  from {
    --angle: 0deg;
  }
  to {
    --angle: 360deg;
  }
}

.plan-type {
  font-size: 18px;
  font-weight: 500;
  color: rgba(255, 255, 255, 0.6);
}

.card-title h3 {
  font-size: 36px;
  font-weight: 500;
}

.card-time {
  font-size: 14px;
  font-weight: 300;
  color: rgba(255, 255, 255, 0.6);
}

.card-info ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
  list-style: none;
  padding: 0;
  font-size: 16px;
  font-weight: 300;
}

.card-info li.active::before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  background-color: #6528f7;
  border: 1px solid #6528f7;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}

.card-info li.inactive::before {
  content: "";
  display: inline-block;
  width: 6px;
  height: 6px;
  border: 1px solid #f7282b;
  border-radius: 50%;
  margin-right: 10px;
  vertical-align: middle;
}

.cards-container {
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 60px;
}

.testimonial,
.testimonials-carousel .carousel-item {
  width: 230px;
  height: fit-content;
  padding: 14px;
  background-color: #1e1e1e;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.testimonials-carousel {
  display: none;
}

.testimonial p,
.testimonials-carousel .carousel-item p {
  line-height: 26px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 300;
  width: 100%;
}

.testimonial-header {
  display: flex;
  gap: 8px;
  align-items: center;
}

.testimonial-header span {
  font-size: 16px;
}

.testimonials-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: masonry;
  max-width: 1190px;
  gap: 10px;
  height: fit-content;
}

#testimonials {
  background: linear-gradient(0deg, #131313 50%, #0d0d0d 100%);
}

.about-container {
  display: flex;
  justify-content: center;
  gap: 82px;
}

.profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}

.profile-info {
  display: flex;
  gap: 10px;
  flex-direction: column;
  align-items: center;
}

.profile-info h4 {
  font-size: 16px;
  font-weight: 300;
}

.profile-info span {
  font-size: 16px;
  font-weight: bold;
}

#contact {
  height: 100%;
  background-image: url("../public/assets/formbkg.svg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 40px 20px;
  gap: 2rem;
}

#contact .title-container {
  margin-bottom: 30px;
}

.form-wrapper {
  max-width: 650px;
  width: 100%;
  transition: opacity 0.5s ease;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.input-group {
  display: flex;
  gap: 1rem;
}

input,
textarea {
  background-color: rgba(0, 0, 0, 0.3);
  border: none;
  border-radius: 4px;
  padding: 1rem;
  color: white;
  width: 100%;
  outline: none;
  font-weight: 300;
  font-family: "Inter", serif;
}

textarea {
  resize: none;
  min-height: 150px;
}

input::placeholder,
textarea::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.customBtn {
  background-color: #6528f7;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 16px;
  cursor: pointer;
  width: 100%;
  font-size: 16px;
  transition: background-color 0.3s;
  font-weight: 600;
  font-family: "Inter", serif;
}

.customBtn :hover {
  background-color: #6f32ff;
}

.success-message {
  max-width: 650px;
  width: 100%;
  min-height: 274px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.5s ease;
  position: relative; /* Cambiado de absolute a relative */
}

.success-message.visible {
  opacity: 1;
}

.hidden {
  display: none;
}

.success-content {
  text-align: center;
  color: white;
}

.success-content h3 {
  margin: 20px 0 10px;
  font-size: 24px;
}

.success-content p {
  margin: 0;
  opacity: 0.8;
}

.checkmark {
  width: 52px;
  height: 52px;
  margin: 0 auto;
}

.checkmark-circle {
  stroke: #6528f7;
  stroke-width: 2;
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark-check {
  stroke: #6528f7;
  stroke-width: 2;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}
footer {
  background-color: #131313;
}
.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  gap: 5px;
}
.footer-container span {
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;
}
#imgContainer {
  display: block;
}

/* Media queries para hacer el sitio responsive */
@media screen and (max-width: 1200px) {
  .testimonials-container {
    grid-template-columns: repeat(4, 1fr);
    max-width: 950px;
  }
}

@media screen and (max-width: 992px) {
  .testimonials-container {
    grid-template-columns: repeat(3, 1fr);
    max-width: 710px;
  }
  #content-container {
    width: 100%;
    max-width: 600px;
  }
  h1 {
    font-size: 48px;
  }
  #text-container p {
    font-size: 20px;
  }
  .about-container {
    gap: 40px;
  }
  .cards-container {
    gap: 30px;
  }
}

@media screen and (max-width: 768px) {
  .navbar {
    flex-direction: row; /* Cambiado a row para mantener logo y botón en línea */
    justify-content: center;
    padding: 20px;
    position: relative;
  }

  .navbar-logo {
    margin: 0; /* Eliminar margen para centrado */
  }

  .navbar-toggle {
    display: block;
    position: absolute;
    right: 20px; /* Posicionar a la derecha */
    top: 50%;
    transform: translateY(-50%);
  }

  .navbar-toggle.active {
    transform: translateY(-50%) rotate(45deg);
  }

  .navbar-links {
    display: flex;
    flex-direction: column;
    gap: 15px;
    text-align: center;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #0d0d0d;
    padding: 0; /* Inicialmente sin padding */
    max-height: 0; /* Inicialmente altura 0 */
    overflow: hidden; /* Ocultar el contenido que se desborda */
    transition: all 0.3s ease-in-out; /* Transición suave */
    opacity: 0;
    z-index: 1000;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  }

  .navbar-links.active {
    padding-bottom: 20px; /* Padding cuando está activo */
    max-height: 300px; /* Altura máxima cuando está activo */
    opacity: 1;
  }

  #home {
    height: auto;
  }
  #home .container {
    flex-direction: column;
    padding: 30px;
    border-radius: 30px;
    text-align: center;
  }
  #text-container {
    align-items: center;
  }
  #testimonials {
    overflow: hidden;
  }

  .testimonials-container {
    display: block;
  }
  .about-container img {
    width: 150px;
  }
  .cards-container {
    flex-direction: column;
    align-items: center;
  }
  #free-card,
  #paid-card {
    width: 100%;
    max-width: 350px;
    height: fit-content;
  }
  .title-container h2 {
    font-size: 36px;
  }

  .title-container p {
    font-size: 16px;
  }
  #download-container {
    justify-content: center;
  }

  .testimonials-container .testimonial {
    display: none;
  }

  .testimonials-carousel {
    display: block;
  }

  .footer-container {
   flex-direction: column;
   align-items: center;
  }
}

@media screen and (max-width: 576px) {
  .input-group {
    flex-direction: column;
  }
  #home .container {
    padding: 20px;
    border-radius: 40px;
  }
  h1 {
    font-size: 36px;
  }
  #text-container p,
  .title-container p {
    display: none;
  }
  .title-container {
    padding: 0 20px;
  }
  .title-container h2 {
    font-size: 28px;
    text-align: center;
  }
  #download-container {
    justify-content: center;
  }
  #download-container img {
    max-width: 200px;
  }
  #phoneImg {
    max-width: 200px;
  }
  .about-container {
    gap: 12px;
  }
  .about-container img {
    width: 120px;
  }

  #free-card,
  #paid-card {
    width: 100%;
    width: 250px;
  }

  .plan-type {
    font-size: 12px;
  }

  .card-title h3 {
    font-size: 32px;
  }

  .card-time {
    font-size: 12px;
  }

  .card-info ul {
    font-size: 12px;
  }
  #contact {
    gap: 0;
  }

  #contact .title-container {
    padding: 0;
  }
}

@media screen and (max-width: 450px) {
  h1 {
    font-size: 26px;
  }
  #home {
    padding: 0;
  }

  #home .container {
    gap: 20px;
  }
  #content-container {
    gap: 12px;
  }

  section {
    padding: 30px 0;
  }

  .title-container {
    margin-bottom: 30px;
  }

  .title-container h2 {
    font-size: 22px;
  }

  .title-container span {
    font-size: 12px;
  }

  .profile {
    display: none;
  }

  .carousel {
    display: block !important;
  }

  #aboutus {
    overflow: hidden;
  }

  .footer-container span {
    font-size: 12px;
  }
}

.carousel {
  position: relative;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  overflow: hidden;
  display: none;
}

.carousel-content {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: transform 0.5s ease;
  gap: 0;
  width: 100vw;
}

.carousel-item {
  flex: 0 0 auto;
  width: 200px;
  transform: scale(0.8);
  transition: all 0.5s ease;
  position: absolute;
}

.about-carousel-item {
  text-align: center;
}

.carousel-item.active {
  opacity: 1;
  transform: scale(1) translateX(0);
  position: relative;
  z-index: 2;
}

.carousel-item.prev {
  transform: scale(0.8) translateX(-125%);
  opacity: 0.5;
  z-index: 1;
}

.carousel-item.next {
  transform: scale(0.8) translateX(125%);
  opacity: 0.5;
  z-index: 1;
}

.about-carousel-item img {
  width: 180px;
  border-radius: 50%;
  object-fit: cover;
}

.carousel-item h4 {
  margin-top: 0.5rem;
  font-size: 1rem;
  font-weight: bold;
}

.carousel-item p {
  color: #888;
  font-size: 0.875rem;
}

.invalid-email::placeholder {
  color: rgba(255, 162, 162, 0.808);
}